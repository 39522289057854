const { dateToYearWeekNumber, getYear } = require('./date');

exports.weeklyReadingUrl = (yyyymmdd /** YYYY-MM-DD */) =>
    `/${getYear(yyyymmdd)}-week-${dateToYearWeekNumber(yyyymmdd)}`;

// Sonic Free Games -> sonic-free-games
const normalizedTag = str => str.replace(/\s+/g, '-').toLowerCase();

exports.weeklyTagHomeUrl = `/weekly/tag`;
exports.weeklyTagUrl = tag => `/weekly/tag/${normalizedTag(tag)}`;
