import React from 'react';
import get from 'lodash/get';
import { Link as GatsbyLink, graphql } from 'gatsby';
import { Link, Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Flex, Spacer
} from '@chakra-ui/react'
import {ExternalLinkIcon} from '@chakra-ui/icons'


import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Footer from '../components/Footer';
import {weeklyTagUrl, weeklyTagHomeUrl} from '../utils/urls';

const PostBlock = ({post}) => {
    if (!post.Note || !post.Note.childMarkdownRemark) {
        console.log(post)
        throw new Error(`${post.Title} doesn't have note content`);
    }
    return (
        <AccordionItem borderColor={"gray.500"} border="1px">
            <h2>
                <AccordionButton backgroundColor="gray.200">
                    <Box flex='1' textAlign='left'>
                        {post.Title}
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
            </h2>
            <AccordionPanel pb={4} px={0} pt={0}>
                <Box>
                    <Flex backgroundColor="gray.100" mb={3} px={3} py={3}>
                        <Link href={post.URL} display="inline-block" isExternal>文章連結<ExternalLinkIcon mx='2px' /></Link>
                        <Spacer />
                        <Box>
                            {post.createdDated}
                        </Box>
                    </Flex>
                    <Box px={3}>
                        <div class="prose">
                            <div
                                dangerouslySetInnerHTML={{ __html: post.Note.childMarkdownRemark.html }}
                            />
                        </div>
                    </Box>
                </Box>
            </AccordionPanel>
        </AccordionItem>
    )
}

const TagLinkButton = ({tag, href, isActive}) => (
    <Box id={tag} display="inline-block" mb={3} mr={2}>
        <Link as={GatsbyLink}
            href={href}
            border="1px"
            borderColor={"gray.400"}
            backgroundColor={isActive ? 'gray.200': "inherit"}
            textColor="gray.800"
            rounded="md"
            py={1}
            px={4}
            alignItems="center"
            _hover={{ backgroundColor: 'gray.100', textDecoration: 'none' }}
        >{tag}</Link>
    </Box>
)

const WeeklyTag = ({ data, location, pageContext }) => {
    const lang = 'zh-hant';
    const siteTitle = get(data, 'site.siteMetadata.title');
    const tagPosts = get(data, 'tagPosts.edges');
    const {allTags, pageTag} = pageContext;

    return (
        <Layout location={location} title={siteTitle} locale={lang}>
            {/* <SEO
                lang={lang}
                title={post.frontmatter.title}
                description={post.frontmatter.description}
                slug={post.fields.slug}
            /> */}
            <Box py={4}>
                <TagLinkButton tag="All" href={weeklyTagHomeUrl} isActive={pageTag.length > 1}/>
                {allTags.map(tag => (
                    <TagLinkButton id={tag} tag={tag} href={weeklyTagUrl(tag)} isActive={pageTag.length === 1 && tag === pageTag[0]} />
                ))}
            </Box>
            <Accordion allowMultiple>
                {tagPosts.map(({node}) => {
                    return (
                        <PostBlock post={node.data} />
                    )
                })}
            </Accordion>
            <Footer locale={lang} />
        </Layout>
    );
};

export default WeeklyTag;

export const pageQuery = graphql`
  query WeeklyTagQuery($pageTag: [String]!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    tagPosts: allAirtableList(
      filter: {
        queryName: { eq: "List" }
        data: { Exclude: { ne: true }, Article_type: { in: $pageTag }, Release__from_Release_: {in: true} }
      }
      sort: { fields: data___sequence }
    ) {
      edges {
        node {
          id
          data {
            Note {
              id
              childMarkdownRemark {
                html
              }
            }
            isTwitter
            Author
            Title
            URL
            Article_type
            createdDated
          }
        }
      }
    }
  }
`;
